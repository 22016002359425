import { TweenMax, TimelineMax } from 'gsap'

export default {
  /*********** animate menu tray and items ***********/

  toggleMobileNav(
    open,
    trayRef,
    navItemsRef,
    dividerRef,
    secondaryNavRef,
    iconsRef,
    phoneRef
  ) {
    const tl = new TimelineMax()
    let tray = trayRef
    let navItems = navItemsRef
    let arr = Array.from(navItems.children)
    let divider = dividerRef
    let secondaryNav = secondaryNavRef
    let arr2 = Array.from(secondaryNav.children)
    let phone = phoneRef
    let icons = iconsRef
    let arr3 = Array.from(icons.children)

    if (open === 'closed') {
      tl.add(
        TweenMax.to(tray, 0.3, {
          width: 300,
          boxShadow: '-10px 0 40px rgba(0, 0, 0, 0.2)'
        })
      )
      tl.add(
        TweenMax.staggerTo(arr, 0.1, { margin: '8px 0', opacity: 1 }, 0.05),
        '-=0.3'
      )
      tl.add(TweenMax.to(divider, 0.1, { opacity: 0.7, margin: '15px 30px' }))
      tl.add(
        TweenMax.staggerTo(arr2, 0.1, { margin: '8px 0', opacity: 0.6 }, 0.05)
      )
      tl.add(TweenMax.to(phone, 0.5, { opacity: 1, margin: 0 }))
      tl.add(TweenMax.staggerTo(arr3, 0.7, { opacity: 1 }, 0.2), '-=0.7')
    } else {
      tl.add(TweenMax.to(tray, 0.3, { width: 0 }))
      tl.add(TweenMax.to(arr, 0, { opacity: 0, margin: '8px -60px 8px 60px' }))
      tl.add(
        TweenMax.to(divider, 0, {
          opacity: 0,
          margin: '15px -300px 15px 300px'
        })
      )
      tl.add(TweenMax.to(arr2, 0, { opacity: 0, margin: '8px -60px 8px 60px' }))
      tl.add(TweenMax.to(arr3, 0, { opacity: 0 }))
      tl.add(TweenMax.to(phone, 0, { opacity: 0, margin: '0 -60px 0 60px' }))
    }
  }
}
