import React, { Fragment } from 'react'
import styled from 'styled-components'

export default ({ children, color, align, className }) => (
  <Fragment>
    <Heading color={color} align={align} className={className}>
      {children}
    </Heading>
    <Divider color={color} align={align} />
  </Fragment>
)

const Heading = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 30pt;
  font-weight: 300;
  font-family: ${props => props.theme.headingFont};
  color: ${props => props.theme[props.color] || 'white'};
  text-align: ${props => props.align || 'left'};

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 24pt;
    text-align: center;
  }
`

const Divider = styled.div`
  margin: 15px ${props => (props.align === 'center' ? 'auto' : '0')} 20px;
  padding: 0;
  width: 50px;
  height: 5px;
  background-color: ${props => props.theme[props.color] || 'white'};

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 15px auto 20px;
  }
`
