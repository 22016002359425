import styled from 'styled-components'

const Image = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 0;
    height: 300px;
    width: 100%;
    display: block;
  }
`

export default Image
