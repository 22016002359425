import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({ text, subLinks }) => (
  <DropdownLink>
    {text}
    <Carat />
    <DropdownContainer>
      {subLinks.map((link, i) => (
        <DropdownItem key={i} to={link.url}>
          {link.text}
        </DropdownItem>
      ))}
    </DropdownContainer>
  </DropdownLink>
)

const DropdownLink = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.navFont};
  font-size: 12pt;
  font-weight: 400;
  margin: 0 20px;
  cursor: default;

  &:hover {
    color: ${(props) => props.theme.lightBlue};
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  display: none;
  flex-direction: column;
  margin: 30px -34px -132px;
  padding: 0;
  background-color: ${(props) => props.theme.primaryColor};
  border-bottom: 5px solid ${(props) => props.theme.lightBlue};

  ${/* sc-sel */ DropdownLink}:hover & {
    display: flex;
  }
`
const Carat = styled.div`
  display: none;
  margin: 0 auto -30px;
  border: 15px solid transparent;
  border-bottom: 15px solid ${(props) => props.theme.primaryColor};
  width: 0;

  ${/* sc-sel */ DropdownLink}:hover & {
    display: block;
  }
`

const DropdownItem = styled(Link)`
  margin: 0 15px;
  padding: 20px;
  font-family: ${(props) => props.theme.navFont};
  font-size: 12pt;
  font-weight: 300;
  color: white;
  cursor: pointer;
  border-top: solid 1px #c9c6c6;

  &:first-of-type {
    border-top: none;
  }

  &:hover {
    color: ${(props) => props.theme.lightBlue};
  }
`
