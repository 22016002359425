import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Heading from 'components/Heading'
import ImageDiv from 'components/styled/ImageDiv'

export default ({ src }) => {
  return (
    <Image image={src}>
      <Overlay>
        <Heading>Your child's home away from home</Heading>
        <Enroll to="/contact">Enroll Now</Enroll>
      </Overlay>
    </Image>
  )
}

const Image = styled(ImageDiv)`
  width: 100%;
  height: 300px;
`
const Overlay = styled.div`
  background-color: rgba(18, 20, 44, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Enroll = styled(Link)`
  border: none;
  display: block;
  padding: 25px 50px;
  font-family: ${(props) => props.theme.navFont};
  font-size: 14pt;
  line-height: 0;
  border-radius: 25px;
  font-weight: 600;
  color: white;
  background-color: ${(props) => props.theme.pink};
  cursor: pointer;
  transition: 0.5s;
  outline: none;

  &:hover {
    color: ${(props) => props.theme.pink};
    background-color: white;
    box-shadow: ${(props) => props.theme.boxShadow};
  }
`
