import React from 'react'
import styled from 'styled-components'

import SecondaryNav from './Secondary'
import PrimaryNav from './Primary'

export default ({ primaryLinks, secondaryLinks, logo }) => (
  <Nav>
    <SecondaryNav links={secondaryLinks} />
    <PrimaryNav logo={logo} links={primaryLinks} />
  </Nav>
)

const Nav = styled.div`
  width: 100%;
  position: fixed;
  z-index: 1000;
`
