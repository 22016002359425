import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { ReactComponent as Instagram } from 'icons/instagram.svg'
import { ReactComponent as Facebook } from 'icons/facebook.svg'
import { ReactComponent as Twitter } from 'icons/twitter.svg'
import { ReactComponent as PhoneIcon } from 'icons/phone.svg'

export default ({ links }) => (
  <Background>
    <NavLinks>
      {links.map((link, i) => (
        <NavLink key={i} to={link.url}>
          {link.text}
        </NavLink>
      ))}
    </NavLinks>
    <Phone />
    <PhoneDiv>
      <PhoneHeading>Bentonville 479.250.9098</PhoneHeading>
      <PhoneHeading>Centerton 479.224.2425</PhoneHeading>
    </PhoneDiv>
    <Divider />
    <SocialIcons>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/CasaCastilloNWA/"
      >
        <Fb />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/casacastillonwa/?hl=en"
      >
        <Insta />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/CasaCastilloNWA?lang=en"
      >
        <Tweet />
      </a>
    </SocialIcons>
  </Background>
)

const Background = styled.div`
  width: 100%;
  padding: 15px 0;
  background-color: ${(props) => props.theme.primaryColor};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const NavLinks = styled.div`
  margin: 0 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const NavLink = styled(Link)`
  margin: 0 10px;
  padding: 0;
  color: white;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 10pt;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.lightBlue};
  }
`
const PhoneDiv = styled.div`
  margin: 0 30px 0 5px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const PhoneHeading = styled.p`
  margin: 0;
  padding: 0;
  color: #c9c6c6;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 9pt;
`
// const PhoneNumber = styled.p`
//   margin: 0;
//   padding: 0;
//   color: white;
//   font-family: ${props => props.theme.bodyFont};
//   font-size: 11pt;
//   font-weight: 500;
// `
const Divider = styled.div`
  width: 1px;
  background-color: #c9c6c6;
  height: 30px;
`
const SocialIcons = styled.div`
  margin: 0 15px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Fb = styled(Facebook)`
  margin: 0 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const Phone = Fb.withComponent(PhoneIcon)
const Insta = styled(Fb.withComponent(Instagram))`
  & path {
    fill: white;
  }
`
const Tweet = Fb.withComponent(Twitter)
