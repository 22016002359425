import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Dropdown from './Dropdown'

export default ({ links, logo }) => (
  <Background>
    <Link to="/">
      <Logo src={logo.gatsbyImageData.images.fallback.src} />
    </Link>
    <NavLinks>
      {links.map((link, i) => {
        return link.subLinks ? (
          <Dropdown key={i} {...link} />
        ) : (
          <LinkItem key={i} to={link.url}>
            {link.text}
          </LinkItem>
        )
      })}
    </NavLinks>
  </Background>
)

const Background = styled.div`
  margin: 0;
  background-color: white;
  box-shadow: ${(props) => props.theme.boxShadow};
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  margin: 10px 30px;
  height: 52px;
`

const NavLinks = styled.div`
  margin: 0 20px 0 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LinkItem = styled(Link)`
  margin: 0 20px;
  font-family: ${(props) => props.theme.navFont};
  font-size: 12pt;
  font-weight: 800;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.lightBlue};
  }
`
