exports.components = {
  "component---src-templates-about-index-js": () => import("./../../../src/templates/about/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-after-school-index-js": () => import("./../../../src/templates/after-school/index.js" /* webpackChunkName: "component---src-templates-after-school-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-dual-immersion-index-js": () => import("./../../../src/templates/dual-immersion/index.js" /* webpackChunkName: "component---src-templates-dual-immersion-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-infants-index-js": () => import("./../../../src/templates/infants/index.js" /* webpackChunkName: "component---src-templates-infants-index-js" */),
  "component---src-templates-music-program-index-js": () => import("./../../../src/templates/music-program/index.js" /* webpackChunkName: "component---src-templates-music-program-index-js" */),
  "component---src-templates-new-facility-index-js": () => import("./../../../src/templates/new-facility/index.js" /* webpackChunkName: "component---src-templates-new-facility-index-js" */),
  "component---src-templates-parents-index-js": () => import("./../../../src/templates/parents/index.js" /* webpackChunkName: "component---src-templates-parents-index-js" */),
  "component---src-templates-preschool-index-js": () => import("./../../../src/templates/preschool/index.js" /* webpackChunkName: "component---src-templates-preschool-index-js" */),
  "component---src-templates-summer-programs-index-js": () => import("./../../../src/templates/summer-programs/index.js" /* webpackChunkName: "component---src-templates-summer-programs-index-js" */),
  "component---src-templates-toddlers-index-js": () => import("./../../../src/templates/toddlers/index.js" /* webpackChunkName: "component---src-templates-toddlers-index-js" */)
}

