import React from 'react'
import styled from 'styled-components'

export default ({ logo }) => (
  <Background>
    <Logo src={logo.gatsbyImageData.images.fallback.src} />
    <Copyright>© 2018 Casa Castillo | Bentonville, Arkansas</Copyright>
  </Background>
)

const Background = styled.div`
  background-color: #1c2c46;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img`
  height: 30px;
  margin: 10px 0;
`
const Copyright = styled.p`
  color: #959fae;
  font-size: 8pt;
`
