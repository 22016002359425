import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import theme, { GlobalStyle } from 'theme'
import Seo from 'components/SEO'
import Navbar from 'components/Navbar'
import EnrollNow from 'components/EnrollNow'
import Footer from 'components/Footer'

const TemplateWrapper = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutTemplateQuery {
      site {
        meta: siteMetadata {
          title
        }
      }
      logo: imageSharp(original: { src: { regex: "/favicon/" } }) {
        gatsbyImageData
      }
      navLogo: imageSharp(
        original: { src: { regex: "/casa-castillo-logo/" } }
      ) {
        gatsbyImageData
      }
      footerLogo: imageSharp(original: { src: { regex: "/footer-logo/" } }) {
        gatsbyImageData
      }
      ctaImage: imageSharp(original: { src: { regex: "/kids-reading/" } }) {
        gatsbyImageData
      }
    }
  `)
  const { logo, site, navLogo, footerLogo, ctaImage } = data
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <title>{site.meta.title}</title>
          <link rel="icon" href={logo.gatsbyImageData.images.fallback.src} />
          <link rel="stylesheet" href="https://use.typekit.net/rlh3rwl.css" />
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,800,900"
          />
        </Helmet>
        <Seo />
        <GlobalStyle />
        <Navbar logo={navLogo} />
        <Main>{children}</Main>
        <EnrollNow src={ctaImage.gatsbyImageData.images.fallback.src} />
        <Footer logo={footerLogo} />
      </Fragment>
    </ThemeProvider>
  )
}

export default TemplateWrapper

const Main = styled.main`
  padding-top: 135px;

  @media (max-width: ${(props) => props.theme.desktop}px) {
    padding-top: 72px;
  }
`
