import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import animate from './Animations'

import Hamburger from './HamburgerIcon'

import { ReactComponent as Instagram } from 'icons/instagram.svg'
import { ReactComponent as Facebook } from 'icons/facebook.svg'
import { ReactComponent as Twitter } from 'icons/twitter.svg'
import { ReactComponent as PhoneIcon } from 'icons/phone.svg'

export default class MobileNav extends Component {
  state = {
    tray: 'closed',
  }

  handleClick = () => {
    const { tray } = this.state
    tray === 'closed'
      ? this.setState({ tray: 'open' })
      : this.setState({ tray: 'closed' })

    animate.toggleMobileNav(
      tray,
      this.trayRef,
      this.navItemsRef,
      this.dividerRef,
      this.secondaryNavRef,
      this.iconsRef,
      this.phoneRef
    )
  }

  render() {
    const { tray } = this.state
    const { primaryLinks, secondaryLinks, logo } = this.props

    return (
      <Fragment>
        <WhiteBar>
          <Link to="/">
            <Logo src={logo.gatsbyImageData.images.fallback.src} />
          </Link>
        </WhiteBar>
        <Hamburger open={tray} click={this.handleClick} />
        <Menu
          innerRef={(x) => {
            this.trayRef = x
          }}
          open={tray}
        >
          <NavContainer open={tray}>
            <NavItems
              innerRef={(x) => {
                this.navItemsRef = x
              }}
            >
              {primaryLinks.map((item, i) => {
                return (
                  <NavItem key={i} to={item.url} onClick={this.handleClick}>
                    {item.text}
                  </NavItem>
                )
              })}
            </NavItems>
            <Divider
              innerRef={(x) => {
                this.dividerRef = x
              }}
            />
            <SecondaryNav
              innerRef={(x) => {
                this.secondaryNavRef = x
              }}
            >
              {secondaryLinks.map((item, i) => {
                return (
                  <SecondaryLink
                    key={i}
                    to={item.url}
                    onClick={this.handleClick}
                  >
                    {item.text}
                  </SecondaryLink>
                )
              })}
            </SecondaryNav>
            <PhoneDiv
              innerRef={(x) => {
                this.phoneRef = x
              }}
            >
              <Phone />
              <PhoneInfo>
                <PhoneHeading>Give us a call</PhoneHeading>
                <PhoneNumber>479 250 9098</PhoneNumber>
              </PhoneInfo>
            </PhoneDiv>
            <SocialIcons
              innerRef={(x) => {
                this.iconsRef = x
              }}
            >
              <Icon
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/CasaCastilloNWA/"
              >
                <Fb />
              </Icon>
              <Icon
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/casacastillonwa/?hl=en"
              >
                <Insta />
              </Icon>
              <Icon
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/CasaCastilloNWA?lang=en"
              >
                <Tweet />
              </Icon>
            </SocialIcons>
          </NavContainer>
        </Menu>
      </Fragment>
    )
  }
}

const Logo = styled.img`
  margin: 10px 20px;
  height: 52px;
`
const WhiteBar = styled.div`
  width: 100%;
  height: 72px;
  position: fixed;
  background-color: white;
  z-index: 1000;
`
const Menu = styled.div`
  width: 0;
  min-height: 100vh;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  position: fixed;
  right: 0;
  z-index: 1001;
`
const NavContainer = styled.div`
  overflow: ${(props) => (props.tray === 'open' ? 'scroll' : 'hidden')};
`
const NavItems = styled.div`
  width: 100%;
  padding: 0 30px;
  margin: 25px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`
const NavItem = styled(Link)`
  margin: 8px -60px 8px 60px;
  opacity: 0;
  font-size: 14pt;
  font-family: ${(props) => props.theme.navFont};
  color: white;
`
const Divider = styled.div`
  width: 200px;
  height: 1px;
  background-color: white;
  margin: 10px -300px 10px 300px;
`
const SecondaryNav = styled.div`
  width: 100%;
  padding: 0 30px;
  margin: 0 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`
const SecondaryLink = styled(Link)`
  margin: 8px -60px 8px 60px;
  opacity: 0;
  font-size: 12pt;
  font-family: ${(props) => props.theme.bodyFont};
  color: white;
`
const SocialIcons = styled.div`
  width: 160px;
  margin: 30px 70px 0 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 15px;
`
const Fb = styled(Facebook)`
  margin: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
`
const Phone = styled(Fb.withComponent(PhoneIcon))`
  margin: 0;
  width: 35px;
  height: 35px;
`
const Insta = styled(Fb.withComponent(Instagram))`
  & path {
    fill: white;
  }
`
const Tweet = Insta.withComponent(Twitter)
const Icon = styled.a`
  opacity: 0;
`
const PhoneDiv = styled.div`
  width: 100%;
  height: 36px;
  padding: 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  margin: 0 -60px 0 60px;
`
const PhoneInfo = styled.div`
  width: 150px;
  margin: 0 10px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const PhoneHeading = styled.p`
  margin: 2px 0;
  padding: 0;
  color: #c9c6c6;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 9pt;
`
const PhoneNumber = styled.p`
  margin: 0;
  padding: 0;
  color: white;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 11pt;
  font-weight: 500;
`
