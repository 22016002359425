import React, { Fragment } from 'react'
import styled from 'styled-components'

// import Subscribe from './Subscribe'
import Copyright from './Copyright'

export default ({ logo }) => (
  <Fragment>
    <Background>
      <Column>
        <Title>Bentonville Location</Title>
        <Text>3906 E Central Ave, Bentonville, AR 72712</Text>
        <Directions
          target="_blank"
          href="https://www.google.com/maps/dir/?api=1&destination=Casa+Castillo%Bentonville&Arkansas"
        >
          Get Directions
        </Directions>
      </Column>
      <Column>
        <Title>Centerton Location</Title>
        <Text>148 Seba Rd, Centerton, Ar 72719</Text>
        <Directions
          target="_blank"
          href="https://www.google.com/maps/place/148+Seba+Rd,+Centerton,+AR+72719/@36.3732909,-94.2871218,17z/data=!3m1!4b1!4m5!3m4!1s0x87c905907e094ff5:0xbe5a772ea1e3bec4!8m2!3d36.3732909!4d-94.2849278"
        >
          Get Directions
        </Directions>
      </Column>
    </Background>
    <Copyright logo={logo} />
  </Fragment>
)

const Background = styled.div`
  margin: 0;
  padding: 20px 4% 0;
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 670px) {
    flex-direction: column;
    flex-direction: column-reverse;
    padding: 20px;
  }
`
const Column = styled.div`
  margin: 20px 0;
  width: 45%;
  padding: 0 10px;

  @media (max-width: 670px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`
const Title = styled.h2`
  color: #959fae;
  font-size: 14pt;
  font-weight: 600;
  width: 80%;
  border-bottom: 1px solid #959fae;
  margin: 0 0 15px;
  padding: 0 0 5px;

  @media (max-width: 670px) {
    text-align: center;
    width: 100%;
  }
`
const Text = styled.p`
  color: #959fae;
  font-size: 12pt;
  line-height: 16pt;
  margin: 15px 0;

  @media (max-width: 670px) {
    text-align: center;
  }
`

const Directions = styled.a`
  display: inline-block;
  margin: 10px 0;
  padding: 10px 30px;
  color: #959fae;
  background-color: transparent;
  border: 2px solid #959fae;
  outline: none;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    color: white;
    background-color: ${props => props.theme.lightBlue};
    border: 2px solid ${props => props.theme.lightBlue};
  }

  @media (max-width: 670px) {
    margin: 15px auto;
  }
`
