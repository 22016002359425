import React from 'react'
import Media from 'react-media'

import MobileNav from './Mobile'
import DesktopNav from './Desktop'

// configure all navigation here; all links in desktop and
// mobile nav are rendered based on this data
const navLinks = {
  primaryLinks: [
    { text: 'Infants', url: '/infants' },
    { text: 'Toddlers', url: '/toddlers' },
    { text: 'Preschool', url: '/preschool' },
    { text: 'School Age', url: '/after-school' },
  ],
  secondaryLinks: [
    { text: 'Dual Immersion', url: '/dual-immersion' },
    // { text: 'Locations', url: '/new-facility' },
    { text: 'Parents', url: '/parents' },
    { text: 'About', url: '/about' },
    { text: 'Contact', url: '/contact' },
  ],
}
const mobileNavLinks = {
  primaryLinks: [
    { text: 'Infants', url: '/infants' },
    { text: 'Toddlers', url: '/toddlers' },
    { text: 'Preschool', url: '/preschool' },
    { text: 'School Age', url: '/after-school' },
  ],
  secondaryLinks: [
    { text: 'Dual Immersion', url: '/dual-immersion' },
    // { text: 'Locations', url: '/new-facility' },
    { text: 'New Parents', url: '/new-parents' },
    { text: 'About', url: '/about' },
    { text: 'Contact', url: '/contact' },
  ],
}

export default ({ logo }) => (
  <Media query="(max-width: 950px)">
    {(matches) =>
      matches ? (
        <MobileNav {...mobileNavLinks} logo={logo} />
      ) : (
        <DesktopNav {...navLinks} logo={logo} />
      )
    }
  </Media>
)
